<template>
<div class="rankings-page">
  <div class="header">
    Bruinclubs Rankings
  </div>
  <div class="prizes">
    <router-link to="/prizes">
      Prizes and info
    </router-link>
  </div>
  <div v-for="club, i in sortedClubs" :key="club._id" class="club-ranked">
    {{i+1}}) 
    <span class="club-ranked-link"  @click="viewClubPage(club._id)">
      {{club['Club Name']}}
    </span>
    <i  v-if="club['Verified']" class="fas fa-check-circle"></i>
    
  </div>
</div>
</template>

<script>
export default {
  props: ['fullClubList'],
  data(){
    return{
      sortedClubs: [],
    }
  },
  watch:{
    fullClubList(){
      this.rankClubs();
    },
  },
  mounted(){
    this.rankClubs();
  },
  methods:{
    rankClubs(){
      if(!this.fullClubList)
        return;
      this.sortedClubs = this.fullClubList.slice(0);
      this.sortedClubs.sort((a,b) => this.rankingAlgo(a,b))
    },
    rankingAlgo(a, b){
      let revA = a.Reviews ? a.Reviews.length : 0;
      let revB = b.Reviews ? b.Reviews.length : 0;
      if(revB != revA)
        return revB - revA;
      return b['Club Summary'].length - a['Club Summary'].length;
    },
    viewClubPage(id){
      this.$router.push({
        path: '/clubpages/'+id
      });
    }
  }
}
</script>

<style scoped>
.rankings-page{
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
}
.header{
  font-size: 24px;
  margin-bottom: 20px;
}
.club-ranked{
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px 0;
}
.club-ranked-link{
  color: #007185;
  cursor: pointer;
}
.club-ranked-link:hover{
  color: #C7511F;
  text-decoration: underline;
}
.fa-check-circle{
  color: var(--primary-color);
  margin-left: 5px;
}
@media screen and (max-width: 800px) {
  .rankings-page{
    font-size: 14px;
  }
}
</style>